import styles from "./GenericQuoteBlock.module.scss";
import IconMS from "src/components/utils/IconMS/IconMS";
import classNames from "classnames";

export interface GenericQuoteBlockProps {
  quote: string;
  author: string;
  source: {
    text: string;
    url: string;
  };
  isPullQuote?: boolean;
}

const GenericQuoteBlock: React.FC<GenericQuoteBlockProps> = ({
  quote,
  author,
  source,
  isPullQuote,
}) => {
  const getLanguageFromMeta = () => {
    return document.documentElement.lang || "en";
  };

  return (
    <div
      className={classNames(styles.quoteBlock, {
        [styles.noQuotes]: isPullQuote,
      })}
    >
      <blockquote lang={getLanguageFromMeta()}>
        {quote && <p className={styles.quote}>{quote}</p>}
        {author && !isPullQuote && (
          <cite className={styles.author}>{author}</cite>
        )}
      </blockquote>

      {source?.url && (
        <a className={styles.sourceLink} href={source.url}>
          <span className={styles.linkText}>{source.text}</span>
          <IconMS className={styles.linkIcon} name="open_in_new" size="16px" />
        </a>
      )}
    </div>
  );
};

export default GenericQuoteBlock;
