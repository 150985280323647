import styles from "./ReportPage.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import ReportPageHeroBlock from "components/blocks/ReportPageHeroBlock/ReportPageHeroBlock";
import { ReportPageHeroBlockProps } from "components/blocks/ReportPageHeroBlock/ReportPageHeroBlock";
import { TopPageBlocksProps } from "components/shared/TopPageBlocks/TopPageBlocks";
import CardListBlock, {
  CardListBlockProps,
} from "components/blocks/CardListBlock/CardListBlock";
import { AlertBlock } from "SiteComponents";
import ContactBlock, {
  ContactBlockProps,
} from "components/blocks/ContactBlock/ContactBlock";

export interface ReportPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  contactBlock?: ContactBlockProps;
  hero: ReportPageHeroBlockProps;
  reportChapters?: CardListBlockProps;
  relatedReports?: CardListBlockProps;
}

const ReportPage: React.FC<ReportPageProps> = ({
  contentArea,
  contactBlock,
  hero,
  alert,
  mainIntro,
  reportChapters,
  relatedReports,
}) => {
  return (
    <main className={styles.reportPage} id="main-content">
      {hero && <ReportPageHeroBlock {...hero} />}
      <div className={styles.grid}>
        {(alert || mainIntro) && (
          <div className={styles.main}>
            {alert && <AlertBlock {...alert} />}
            {mainIntro && <p className={styles.mainIntro}>{mainIntro}</p>}
          </div>
        )}

        {contactBlock && (
          <div className={styles.aside}>
            <ContactBlock {...contactBlock} />
          </div>
        )}

        <div className={styles.main}>
          {(contentArea || relatedReports) && contentArea && (
            <ContentArea
              className={styles.contentArea}
              {...contentArea}
              noGrid
            />
          )}
          {reportChapters && reportChapters.listItems && (
            <CardListBlock {...reportChapters} />
          )}
        </div>
        <div className={styles.fullWidth}>
          {relatedReports && <CardListBlock {...relatedReports} />}
        </div>
      </div>
    </main>
  );
};

export default ReportPage;
