import { LinkProps } from "src/base-props/LinkProps";
import styles from "./ReportReadPage.module.scss";
import ReportReadPageHeroBlock from "components/blocks/ReportReadPageHeroBlock/ReportReadPageHeroBlock";
import GlobalCardBlock, {
  GlobalCardBlockProps,
} from "components/blocks/GlobalCardBlock/GlobalCardBlock";
import { AccordionItemBlock, Button, IconMS } from "SiteComponents";
import { useEffect, useState } from "react";
import classNames from "classnames";
import useWindowSize from "src/utils/useWindowSize";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import "./ReportReadPage.scss";

export interface ReportReadPageProps {
  heading: string;
  subHeading?: string;
  mainIntro?: string;
  contentArea: ContentAreaProps;
  navigation?: NavigationProps[];
  reportFrontPageLink?: GlobalCardBlockProps;
  prevChapterLink?: GlobalCardBlockProps;
  nextChapterLink?: GlobalCardBlockProps;
  currentChapterIndex: number;
  contentTitle: string;
  closeNavigationButtonLabel: string;
  abortNavigationButtonLabel: string;
}

interface NavigationProps {
  heading: string;
  subChapterLinks: LinkProps[];
}

const ReportReadPage = ({
  heading,
  subHeading,
  reportFrontPageLink,
  mainIntro,
  contentArea,
  navigation,
  prevChapterLink,
  nextChapterLink,
  currentChapterIndex,
  contentTitle: contentHeading,
  closeNavigationButtonLabel,
  abortNavigationButtonLabel,
}: ReportReadPageProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [navigationOpen, setNavigationOpen] = useState(false);

  const isDesktop = useWindowSize().width >= 1024;

  useEffect(() => {
    const handleResize = (event: CustomEvent<{ headerHeight: number }>) => {
      setOffsetHeight(event?.detail?.headerHeight);
    };

    const handleScroll = () => {
      const anchors: number[] = [];
      const scrollPos = window.scrollY;

      anchors.some((anchor, index) => {
        if (
          (index < anchors.length - 1 &&
            anchor <= scrollPos &&
            anchors[index + 1] >= scrollPos) ||
          (index === anchors.length - 1 && anchor <= scrollPos)
        ) {
          setActiveIndex(index);
          return true;
        }
        return false;
      });
    };

    document.addEventListener("headerResize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("headerResize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!document) return;
    document.body.classList.toggle("noscroll", navigationOpen)
  }, [navigationOpen])

  return (
    <div className={styles.reportReadPage}>
      <ReportReadPageHeroBlock
        heading={heading}
        subHeading={subHeading}
        reportFrontPageLink={reportFrontPageLink}
        className={styles.hero}
      />
      <div className={styles.gridContainer}>
        <div
          className={classNames(styles.navigationContainer, {
            [styles.modal]: !isDesktop && navigationOpen,
          })}
        >
          {!isDesktop && !navigationOpen && (
            <Button
              className={styles.navigationButton}
              theme="secondary"
              onClick={() => setNavigationOpen(true)}
            >
              <IconMS name="format_list_bulleted" />
              {contentHeading}
            </Button>
          )}
          {!isDesktop && navigationOpen && (
            <div className={styles.navigationHeader}>
              <h2 className={styles.navigationTitle}>{contentHeading}</h2>
              <Button
                theme="tertiary"
                buttonText={closeNavigationButtonLabel}
                onClick={() => setNavigationOpen(false)}
              ></Button>
            </div>
          )}
          {(isDesktop || navigationOpen) && (
            <div
              className={classNames(styles.navigation, {})}
              style={{ top: isDesktop ? offsetHeight : "unset" }}
            >
              {navigation &&
                navigation.length > 0 &&
                navigation.map(
                  (chapter, index) =>
                    chapter && (
                      <AccordionItemBlock
                        small
                        heading={chapter.heading}
                        linkList={chapter.subChapterLinks}
                        initiallyOpen={index === currentChapterIndex}
                        activeIndex={
                          index === currentChapterIndex
                            ? activeIndex
                            : undefined
                        }
                        onClick={() => setNavigationOpen(false)}
                      />
                    )
                )}
            </div>
          )}
          {!isDesktop && navigationOpen && (
            <div className={styles.closeNavigation}>
              <Button
                className={styles.closeNavigationButton}
                theme="secondary"
                buttonText={abortNavigationButtonLabel}
                onClick={() => setNavigationOpen(false)}
              />
            </div>
          )}
        </div>
        <div className={styles.main}>
          {mainIntro && <p className={styles.mainIntro}>{mainIntro}</p>}
          {contentArea && (
            <ContentArea
              className={styles.contentArea}
              {...contentArea}
              noGrid
            />
          )}
        </div>
        <div className={styles.cardList}>
          {prevChapterLink ? (
            <GlobalCardBlock
              className={styles.prevChapter}
              {...prevChapterLink}
              theme="outlined"
              icon="arrow_back"
              type="ReportReadPage"
            />
          ) : (
            reportFrontPageLink && (
              <GlobalCardBlock
                className={styles.prevChapter}
                {...reportFrontPageLink}
                theme="outlined"
                icon="undo"
                type="ReportReadPage"
              />
            )
          )}
          {nextChapterLink ? (
            <GlobalCardBlock
              className={styles.nextChapter}
              {...nextChapterLink}
              theme="outlined"
              icon="arrow_forward"
              type="ReportReadPage"
              iconRight
            />
          ) : (
            reportFrontPageLink && (
              <GlobalCardBlock
                className={styles.nextChapter}
                {...reportFrontPageLink}
                theme="outlined"
                icon="undo"
                type="ReportReadPage"
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportReadPage;
