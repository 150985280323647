import classNames from "classnames";
import styles from "./GridContainer.module.scss";
import { Small } from "components/blocks/ContactBlock/ContactBlock.stories";

export interface GridContainerStyleProps {
  fullWidth?: boolean;
  verticalPadding?: boolean;
  noVertical?: boolean;
  noHorizontal?: boolean;
  horizontalSpacing?: "md" | "lg";
  className?: string;
  id?: string;
  noTopMargin?: boolean;
  smallerTopMargin?: boolean;
}

interface GridContainerProps extends GridContainerStyleProps {
  children: React.ReactNode;
}

const GridContainer: React.FC<GridContainerProps> = ({
  children,
  fullWidth = false,
  verticalPadding = false,
  noVertical,
  noHorizontal,
  className,
  id,
  horizontalSpacing = "md",
  noTopMargin = false,
  smallerTopMargin = false,
}) => {
  return (
    <div
      className={classNames(styles.gridContainer, className, {
        [styles.fullWidth]: fullWidth,
        [styles.verticalPadding]: verticalPadding,
        [styles.noVertical]: noVertical,
        [styles.noHorizontal]: noHorizontal,
        [styles.horizontalSpacingLarge]: horizontalSpacing === "lg",
        [styles.noTopMargin]: noTopMargin,
        [styles.smallerTopMargin]: smallerTopMargin,
      })}
      id={id}
    >
      {children}
    </div>
  );
};

export default GridContainer;
