import styles from "./ResultsPage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

import { FilterBlockProps } from "components/blocks/ContentManagerBlock/FilterBlock/FilterBlock";
import ResultsPageHeroBlock, {
  ResultsPageHeroBlockProps,
} from "components/blocks/ResultsPageHeroBlock/ResultsPageHeroBlock";

export interface ResultsPageProps {
  contentArea: ContentAreaProps;
  hero: ResultsPageHeroBlockProps;
  filterBlock?: FilterBlockProps;
}

const ResultsPage: React.FC<ResultsPageProps> = ({ contentArea, hero }) => {
  return (
    <main className={styles.resultsPage} id="main-content">
      {hero && <ResultsPageHeroBlock {...hero} />}
      {contentArea && <ContentArea {...contentArea} noStyling />}
    </main>
  );
};

export default ResultsPage;
