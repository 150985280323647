import styles from "./LinkGridBlock.module.scss";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import LinkGrid from "./LinkGrid/LinkGrid"; 
import {LinkGridItemProps} from "./LinkGridItem/LinkGridItem"; 
import { OverrideTheme } from "SiteComponents";

export interface LinkGridBlockProps {
    title?: string;
    linkItem?: CTAProps;
    cardType: "medium" | "small";
    listItems?: LinkGridItemProps[];
}

const LinkGridBlock: React.FC<LinkGridBlockProps> = ({
    title,
    linkItem,
    cardType,
    listItems,
}) => {
    
    return (
        <div className={styles.linkGridBlock}>
            <div className={styles.header}>
                {title && <h2>{title}</h2>}
                {linkItem && (
                    <div className={styles.linkTarget}>
                        <OverrideTheme theme="light">
                            <CTA
                                text={linkItem.text}
                                url={linkItem.url}
                                theme="tertiary"
                                size="small"
                                target={linkItem.target}
                            />
                        </OverrideTheme>
                    </div>
                )}
            </div>
            <LinkGrid listItems={listItems} type={cardType ?? "small"}/>
        </div>
    );
};

export default LinkGridBlock;