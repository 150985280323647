import styles from "./SectionPage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import SectionPageHeroBlock, {
  SectionPageHeroBlockProps,
} from "components/blocks/SectionPageHeroBlock/SectionPageHeroBlock";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import { FilterBlock } from "SiteComponents";
import { FilterBlockProps } from "components/blocks/ContentManagerBlock/FilterBlock/FilterBlock";

export interface SectionPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: SectionPageHeroBlockProps;
  filterBlock?: FilterBlockProps;
}

const SectionPage: React.FC<SectionPageProps> = ({
  alert,
  contentArea,
  hero,
  contentAnchors,
  filterBlock,
}) => {
  return (
    <main className={styles.sectionPage} id="main-content">
      {hero && <SectionPageHeroBlock {...hero} />}
      <TopPageBlocks
        width="wide"
        alert={alert}
        contentAnchors={contentAnchors}
      />
      {filterBlock && <FilterBlock {...filterBlock} />}
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default SectionPage;
