import classNames from "classnames";
import styles from "./LinkGrid.module.scss";
import LinkGridItem, { LinkGridItemProps } from "../LinkGridItem/LinkGridItem";

export interface LinkGridProps {
  type: "medium" | "small";
  listItems?: LinkGridItemProps[];
}

const LinkGrid: React.FC<LinkGridProps> = ({ type, listItems }) => {
  if (!listItems || listItems.length === 0) return null;

  return (
    <ul
      className={classNames(styles.linkGrid, {
        [styles.isMedium]: type === "medium",
        [styles.isSmall]: type === "small",
      })}
    >
      {listItems.map((gridItem, index) => (
        <li key={index} className={styles.gridItem}>
          <LinkGridItem {...gridItem} type={type} />
        </li>
      ))}
    </ul>
  );
};

export default LinkGrid;
