import styles from "./NewsPageHeroBlock.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import IconMS from "src/components/utils/IconMS/IconMS";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "src/utils/useTheme";

export interface NewsPageHeroBlockProps extends ReactComponentPropsBase {
  heading: string;
  mainIntro?: string;
  publishDate?: string;
}

const NewsPageHeroBlock: React.FC<NewsPageHeroBlockProps> = ({
  heading,
  mainIntro,
  publishDate,
}) => {
  const backgroundClass = useHeroBackground();
  const theme = useTheme();

  return (
    <div className={backgroundClass}>
      <BlockContainer
        className={classNames(styles.newsPageHeroBlock, {
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.newsPageHeroBlockContent}>
          {heading && <h1>{heading}</h1>}
          {mainIntro && <p className={styles.mainIntro}>{mainIntro}</p>}
          {publishDate && (
            <div className={styles.dateContainer}>
              <IconMS name="stylus" />
              <p>
                <span className={styles.label}>{publishDate}</span>
              </p>
            </div>
          )}
        </div>
      </BlockContainer>
    </div>
  );
};

export default NewsPageHeroBlock;
