import React from "react";

interface PanelProps {
  id: string;
  children: React.ReactNode;
  activeTab?: string;
}

export const Panel = ({ id, children, activeTab }: PanelProps) => {
  return activeTab === id ? <div>{children}</div> : null;
};

export default Panel;
