import React, { useState } from "react";
import styles from "./EventPage.module.scss";

import EventPageHeroBlock, {
  EventPageHeroBlockProps,
} from "components/blocks/EventPageHeroBlock/EventPageHeroBlock";
import HighlightedInfoBlock, {
  HighlightedInfoBlockProps,
} from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import ContactBlock, {
  ContactBlockProps,
} from "components/blocks/ContactBlock/ContactBlock";
import { CardListBlock, Tabs } from "SiteComponents";
import { CardListBlockProps } from "components/blocks/CardListBlock/CardListBlock";
import { TabsProps } from "components/utils/Tabs/Tabs";
import ProgramBlock, {
  ProgramBlockProps,
} from "components/blocks/ProgramBlock/ProgramBlock";
export interface EventPageProps {
  contentArea?: ContentAreaProps;
  mainIntro?: string;
  highlightedInfo?: HighlightedInfoBlockProps;
  hero: EventPageHeroBlockProps;
  relatedEvents?: CardListBlockProps;
  contributors?: ContactBlockProps;
  program?: ProgramBlockProps;
  contactBlock?: ContactBlockProps;
  tabs: TabsProps;
}

const EventPage: React.FC<EventPageProps> = ({
  contentArea,
  hero,
  mainIntro,
  highlightedInfo,
  program,
  contactBlock,
  relatedEvents,
  contributors,
  tabs,
}) => {
  const blocksWidth = { xs: 12, lg: 6, lgOffset: 3 };

  const [activePanel, setActivePanel] = useState<{
    panelComponent: React.FC<any>;
    panelComponenProps: any;
  }>();

  const handleTabChange = (id: string) => {
    switch (id) {
      case "program":
        setActivePanel({
          panelComponent: ProgramBlock,
          panelComponenProps: program,
        });
        break;
      case "contributors":
        setActivePanel({
          panelComponent: ContactBlock,
          panelComponenProps: contributors,
        });
        break;
      case "contentArea":
        setActivePanel({
          panelComponent: ContentArea,
          panelComponenProps: { ...contentArea, noGrid: true },
        });
        break;
    }
  };

  return (
    <main className={styles.eventPage} id="main-content">
      {hero && <EventPageHeroBlock {...hero} />}
      <div className={styles.gridContainer}>
        {(mainIntro || highlightedInfo) && (
          <div className={styles.main}>
            {mainIntro && <p className={styles.mainIntro}>{mainIntro}</p>}
            {highlightedInfo && <HighlightedInfoBlock {...highlightedInfo} />}
          </div>
        )}

        <div className={styles.aside}>
          {contactBlock && <ContactBlock {...contactBlock} />}
        </div>

        {tabs && (
          <div className={styles.main}>
            <Tabs
              {...tabs}
              onTabChange={handleTabChange}
              PanelComponent={
                activePanel?.panelComponent
                  ? activePanel.panelComponent
                  : undefined
              }
              panelComponentProps={
                activePanel?.panelComponenProps
                  ? activePanel.panelComponenProps
                  : undefined 
              }
            />
          </div>
        )}
        {relatedEvents && (
          <div className={styles.fullWidth}>
            <CardListBlock {...relatedEvents} />
          </div>
        )}
      </div>
    </main>
  );
};

export default EventPage;
