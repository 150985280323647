export const getCurrentLanguageCode = (languages: any) => {
  if (!languages?.availableLanguages || !languages?.currentLanguage) {
    return "en";
  }

  return (
    languages.availableLanguages.find(
      (lang: any) => lang.languageName === languages.currentLanguage
    )?.languageCode || "en"
  );
};
