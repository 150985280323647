import { ReactNode, FC, useRef, useLayoutEffect } from "react";

export interface FocusTrapProps {
  children: ReactNode;
  enable?: boolean;
  setFocus?: (node: HTMLElement) => void;
  additionalTabableSelectors?: string[];
  excludeSelectors?: string[];
}

const FocusTrap: FC<FocusTrapProps> = ({
  children,
  enable = true,
  setFocus,
  additionalTabableSelectors = [],
  excludeSelectors = [],
}) => { 
  const topTabTrap = useRef<HTMLSpanElement>(null);
  const bottomTabTrap = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!enable) return;

    const focusableElements = getFocusableElements();

    if (focusableElements.length > 0) {
      if (document.activeElement !== focusableElements[0]) {
        // Sometimes the focus does not get set or overridden by something and we need to set it with a timeout
        setFocus
          ? setFocus(focusableElements[0])
          : focusableElements[0].focus();
      }
    }
    function trapFocus(event: KeyboardEvent) {
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (!container.current) return;

      if (event.target === topTabTrap.current && event.shiftKey) {
        event.preventDefault();
        lastElement.focus();
      }

      if (event.target === bottomTabTrap.current && !event.shiftKey) {
        event.preventDefault();
        firstElement.focus();
      }

      if (event.key === "Tab") {
        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    }

    function getFocusableElements() {
      if (!container.current) return [];

      const FOCUSABLE_SELECTOR = [
        "button",
        "a[href]",
        "input",
        "select",
        "textarea",
        "[tabindex]",
        "[contenteditable]",
        "iframe",
        "video",
        ...additionalTabableSelectors,
      ]
        .map(selector => `${selector}:not(:disabled):not([disabled])`)
        .join(", ");

      const allFocusableElements = Array.from(
        container.current.querySelectorAll<HTMLElement>(FOCUSABLE_SELECTOR)
      );

      const excludeElements = excludeSelectors.flatMap(selector =>
        Array.from(container.current!.querySelectorAll<HTMLElement>(selector))
      );

      const excludeElementsAndChildren = new Set();
      excludeElements.forEach(el => {
        excludeElementsAndChildren.add(el);
        el.querySelectorAll("*").forEach(child =>
          excludeElementsAndChildren.add(child)
        );
      });

      return allFocusableElements.filter(
        element =>
          !excludeElementsAndChildren.has(element) &&
          element !== topTabTrap.current &&
          element !== bottomTabTrap.current
      );
    }

    document.addEventListener("keydown", trapFocus);
    return () => document.removeEventListener("keydown", trapFocus);
  }, [topTabTrap, bottomTabTrap, container, enable]);

  return (
    <div ref={enable ? container : null}>
      {enable && <span ref={topTabTrap} tabIndex={0} />}
      {children}
      {enable && <span ref={bottomTabTrap} tabIndex={0} />}
    </div>
  );
};

export default FocusTrap;
