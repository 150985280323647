import styles from "./NewsPage.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import NewsPageHeroBlock from "components/blocks/NewsPageHeroBlock/NewsPageHeroBlock";
import { NewsPageHeroBlockProps } from "components/blocks/NewsPageHeroBlock/NewsPageHeroBlock";
import LinkGridBlock, { LinkGridBlockProps } from "components/blocks/LinkGridBlock/LinkGridBlock";

import { BlockContainer } from "SiteComponents";
import { ContactBlockItemProps } from "components/blocks/ContactBlock/ContactBlockItem/ContactBlockItem";

export interface NewsPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: NewsPageHeroBlockProps;
  relatedArticles?: LinkGridBlockProps;
  authors?: ContactBlockItemProps[];
}

const NewsPage: React.FC<NewsPageProps> = ({
  contentAnchors,
  contentArea,
  hero,
  relatedArticles,
  authors,
}) => {
  return (
    <main className={styles.newsPage} id="main-content">
      {hero && <NewsPageHeroBlock {...hero} />}
      <TopPageBlocks
        contentAnchors={contentAnchors}
        contactBlockItems={authors}
      />
      {contentArea && <ContentArea {...contentArea} />}
      {relatedArticles && (
        <BlockContainer>
          <LinkGridBlock {...relatedArticles} />
        </BlockContainer>
      )}
    </main>
  );
};

export default NewsPage;
