import styles from "./BypassBanner.module.scss";

export interface BypassBannerProps {
  message: string;
}

const BypassBanner: React.FC<BypassBannerProps> = ({ message }) => {
  return (
    <a
      role="banner"
      href="#main-content"
      className={styles.bypassBanner}
      onFocus={() => {
        window.scrollTo(0, 0);
      }}
    >
      <div>
        <span>{message}</span>
      </div>
    </a>
  );
};

export default BypassBanner;
