import React from "react";
import styles from "src/pages-shared/SectionPage/SectionPage.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import SectionPageHeroBlock, {
  SectionPageHeroBlockProps,
} from "components/blocks/SectionPageHeroBlock/SectionPageHeroBlock";

export interface InProStartPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: SectionPageHeroBlockProps;
}

const InProStartPage: React.FC<InProStartPageProps> = ({
  alert,
  contentArea,
  hero,
  contentAnchors,
}) => {
  return (
    <main className={styles.sectionPage} id="main-content">
      {hero && <SectionPageHeroBlock {...hero} />}
      <TopPageBlocks
        width="wide"
        alert={alert}
        contentAnchors={contentAnchors}
      />
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default InProStartPage;
