import classNames from "classnames";
import styles from "./LinkGridItem.module.scss";
import { ImageProps } from "components/base/Image/Image";
import Media from "components/utils/Media/Media";

export interface LinkGridItemProps {
  title: string;
  url: string;
  description: string;
  image?: ImageProps;
  pageType: string;
  type: "medium" | "small";
}

const PlaceholderSVG: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 75"
    preserveAspectRatio="xMidYMid meet"
    className={styles.placeholder}
  >
    <rect width="100%" height="100%" fill="#f0f0f0" />
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      fill="#a0a0a0"
      fontSize="12px"
      dy=".3em"
    >
      No Image
    </text>
  </svg>
);

const LinkGridItem: React.FC<LinkGridItemProps> = ({
  title,
  url,
  description,
  image,
  pageType,
  type,
}) => {
  const isMedium = type === "medium";
  const isSmall = type === "small";
  const showImage = pageType === "infopage";

  return (
    <a
      href={url}
      className={classNames(styles.linkGridItemContainer, {
        [styles.isMedium]: isMedium,
        [styles.isSmall]: isSmall,
        [styles.hasImage]: showImage,
        [styles.noImage]: !showImage,
      })}
    >
      {showImage && (
        <div
          className={classNames(styles.imageContainer, {
            [styles.isMedium]: isMedium,
            [styles.isSmall]: isSmall,
          })}
        >
          {image ? (
            // Show the actual image if it exists
            <Media className={styles.image} image={image} />
          ) : (
            // Show the placeholder only when there's no image
            <PlaceholderSVG />
          )}
        </div>
      )}
      <div
        className={classNames(styles.textBlock, {
          [styles.isMedium]: isMedium,
          [styles.isSmall]: isSmall,
        })}
      >
        <div className={styles.textArea}>
          <div className={styles.title}>{title}</div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </a>
  );
};

export default LinkGridItem;
