import styles from "./ExamplePage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

import ExamplePageHeroBlock, {
  ExamplePageHeroBlockProps,
} from "components/blocks/ExamplePageHeroBlock/ExamplePageHeroBlock";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import LinkGridBlock, { LinkGridBlockProps } from "components/blocks/LinkGridBlock/LinkGridBlock";

import { BlockContainer } from "SiteComponents";

export interface ExamplePageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: ExamplePageHeroBlockProps;
  relatedPages?: LinkGridBlockProps;
}

const ExamplePage: React.FC<ExamplePageProps> = ({
  contentArea,
  hero,
  alert,
  mainIntro,
  contentAnchors,
  relatedPages,
}) => {
  return (
    <main className={styles.ExamplePage} id="main-content">
      {hero && <ExamplePageHeroBlock {...hero} />}

      <TopPageBlocks
        alert={alert}
        mainIntro={mainIntro}
        contentAnchors={contentAnchors}
      />

      {contentArea && <ContentArea {...contentArea} />}
      {relatedPages && (
        <BlockContainer>
          <LinkGridBlock {...relatedPages} />
        </BlockContainer>
      )}
    </main>
  );
};

export default ExamplePage;
